body {
    background-color: #E5E5E5;
}

.circle-overlay {
    transform: scale(0);
}

.circle-overlay {
    position: absolute;
    top: -350px;
    left: -323px;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background: #9063cdf0;
    transition: all .7s ease-in;
    z-index: 1;
}

.card:hover .circle-overlay {
    transform: scale(35);
}

.demo-info {
    position: absolute;
    margin: 0 auto;
    top: 18%;
    left: 0;
    right: 0;
    z-index: 2;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.demo-link {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.3s, opacity 0.3s, color 0.3s;
    transition-delay: 0.2s;
}

.demo-title {
    color: #fff;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s;
    transition-delay: 0.1s;
}

.card:hover .demo-link {
    opacity: 1;
}

.card:hover .demo-title {
    opacity: 1;
    transform: translateY(10px);
}

#id_filterIndustry {
    background-color: #9063cdf0;
    color: white;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

#id_filterIndustry option {
    background-color: white;
    color: black;
}

.btn:focus,
.form-select:focus,
.navbar-toggler:focus {
    box-shadow: none !important;
}

#previewModal .btn-close{
    position: absolute;
    right: 0px;
    top: -7px;
    background-color: #fff;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    z-index: 99999;
    border: 2px solid black;
    box-shadow: 0px 0px 8px 2px #ccc;
    /* background-image: url('../image/icons/close.png'); */
}

.text-gray{
    color: #ccc;
}

.bg-transparent-card{
    background-color: #ffffffb3!important;
}

#resume_container #ResumeLanguage ul, #resume_container #ResumeSkills ul, #resume_container #ResumeCompetencies ul, #resume_container #ResumeJobsummary ul, #resume_container #ResumeProfilePhoto ul{
    list-style-type: disc;
}