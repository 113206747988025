
   .text-secondary-lightgreen{
    color: #01AB91 !important;
  }
  .text-secondary-blue{
    color: #276EF1 !important;
  }
  .text-secondary-violet{
    color: #7356BF !important;
  }
  .text-secondary-brown{
   color: #99644C !important;
  }
  .text-secondary-darkgreen{
    color: #006787 !important;
  }
  .text-secondary-yellow{
    color: #FFC043 !important;
  }
  .bg-secondary-lightgreen-bar{
    background-color: #01AB91 !important;
  }
  .bg-secondary-blue-bar{
    background-color: #276EF1 !important;
  }
  .bg-secondary-violet-bar{
    background-color: #7356BF !important;
  }
  .bg-secondary-brown-bar{
    background-color: #99644C !important;
  }
  .bg-secondary-darkgreen-bar{
    background-color: #006787 !important;
  }
  .bg-secondary-yellow-bar{
    background-color: #FFC043 !important;
  }
  .bg-secondary-lightgrey-bar{
    background-color: #D9D9D9 !important;
  }
  .bg-secondary-lightorange-bar{
    background-color: #ECD8CD !important;
  }